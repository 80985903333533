import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Score",
  code: "Score"
};
export const _frontmatter = {
  "menuLabel": "Score",
  "sortOrder": 19.1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Score`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-score--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Score } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The score component is designed to display live scores in a visually appealing and easily understandable manner. It can handle various formats of scores, including numerical and string values. The component offers built-in animation to highlight score changes, ensuring that updates are noticeable to users. It supports different size and variant options to fit various design requirements and contexts.`}</p>
    <p>{`The score component is highly customizable, allowing you to adjust its appearance through props such as size, variant, and className. It can also be used in conjunction with a skeleton loader for displaying placeholder content while the actual score is being fetched or loaded.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
    <AccessibilityTable.Principle principle="Percievable" status="ready">
        The score component ensures that score updates are easily noticeable through animations, making changes in scores perceivable to all users, including those with visual impairments. It uses clear and distinct styles for different sizes and variants, ensuring that the score is visually distinguishable.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Operable" status="ready">
        The component can be navigated and interacted with using various input methods, including keyboard and mouse, ensuring it is operable for users with different abilities. Focus management and state updates are handled efficiently to ensure smooth operation.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Understandable" status="ready">
        The Score component provides clear labels and values, making it easy for users to understand the current score and any changes.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Robust" status="ready">
        The component is built using standard HTML, CSS, and JavaScript, ensuring compatibility across different browsers and devices.
    </AccessibilityTable.Principle>
    </AccessibilityTable>
    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      